<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row style="padding-bottom:50px">
        <v-col cols="12">
            <!-- <v-card> -->
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="deep-purple accent-4"
                ></v-progress-linear>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" xs="12" sm="2" md="2">
                            <v-autocomplete
                                clearable
                                solo
                                v-model="office"
                                :items="offices"
                                item-value="office_id"
                                item-text="office"
                                label="Office ID"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="2" md="2" >
                            <v-autocomplete
                                clearable
                                solo
                                v-model="vendor"
                                :items="vendors"
                                item-value="vendor_id"
                                item-text="vendor_name"
                                label="Vendor"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col> 
                        <v-col cols="12" xs="12" sm="2" md="2">
                            <v-autocomplete
                                clearable
                                solo
                                v-model="status"
                                :items="statuss"
                                item-value="value"
                                item-text="label"
                                label="Status"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="2" md="2">
                            <v-btn
                                class="mr-2 border-12"
                                color="info"
                                elevation="2"
                                large
                                @click="submit()"
                            >
                                <v-icon dark>
                                    mdi-magnify
                                </v-icon>
                            </v-btn>
                        </v-col>                             
                    </v-row>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </v-container>
            <!-- </v-card> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="padding-bottom: 100px">
            <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
            <v-card class="rounded-xl p-2">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-card-title>Result
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="$store.state.text"
                                    append-icon="mdi-magnify"
                                    label="Search..."
                                    single-line
                                    @keyup.enter="search"
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-data-table
                                :headers="headers"
                                :items="hutangs"
                                :options.sync="options"
                                :loading="loading"
                                loading-text="Please wait, retrieving data"
                                :server-items-length="totalItems"
                                :page.sync="pagination"
                                page-count="10"
                                class="elevation-1"
                            >
                                <template v-slot:[`header.tagihan`]="{ header }">
                                    <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                </template>
                                <template v-slot:[`header.payment`]="{ header }">
                                    <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                </template>
                                <template v-slot:[`header.hutang`]="{ header }">
                                    <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                </template>
                                <template v-slot:[`item.tagihan`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.tagihan) }}
                                </template>
                                <template v-slot:[`item.payment`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.payment) }}
                                </template>
                                <template v-slot:[`item.hutang`]="{ item }">
                                    {{ $store.getters.convertToCurrency(item.hutang) }}
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>Show</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row class="ma-3">
                        <v-col cols="12">
                            <p style="text-align: right;color: #e50400;font-weight: 700;">Total Tagihan = Rp {{ $store.getters.convertToCurrency(total_tagihan) }}</p>
                            <p style="text-align: right;color: #e50400;font-weight: 700;">Total Payment = Rp {{ $store.getters.convertToCurrency(total_payment) }}</p>
                            <p style="text-align: right;color: #e50400;font-weight: 700;">Total Outstanding = Rp {{ $store.getters.convertToCurrency(total_hutang) }}</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
            <v-dialog v-model="dialog" persistent max-width="1200px">                
                <v-card>
                    <v-card-title>Detail </v-card-title>
                    <v-card-text>
                            <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <v-card-title>Detail
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                            v-model="search_text"
                                            append-icon="mdi-magnify"
                                            label="Search vendor"
                                            single-line
                                            @keyup.enter="search_detail"
                                            hide-details
                                        ></v-text-field>
                                    </v-card-title>
                                    <v-data-table
                                        :headers="header_details"
                                        :items="order_details"
                                        :options.sync="option_temp_details"
                                        :loading="loading2"
                                        loading-text="Please wait, retrieving data"
                                        :server-items-length="totalItem_details"
                                        :page.sync="pagination_item"
                                        page-count="10"
                                        class="elevation-1"
                                    >
                                        <template v-slot:[`item.amt_subtotal`]="{ item }">
                                            {{ $store.getters.convertToCurrency(item.amt_subtotal) }}
                                        </template>
                                        <template v-slot:[`item.amt_disc`]="{ item }">
                                            {{ $store.getters.convertToCurrency(item.amt_disc) }}
                                        </template>
                                        <template v-slot:[`item.amt_ppn`]="{ item }">
                                            {{ $store.getters.convertToCurrency(item.amt_ppn) }}
                                        </template>
                                        <template v-slot:[`item.amt_total`]="{ item }">
                                            {{ $store.getters.convertToCurrency(item.amt_total) }}
                                        </template>
                                        <template v-slot:[`item.amt_paid`]="{ item }">
                                            {{ $store.getters.convertToCurrency(item.amt_paid) }}
                                        </template>
                                        <template v-slot:[`item.amt_paid_disc`]="{ item }">
                                            {{ $store.getters.convertToCurrency(item.amt_paid_disc) }}
                                        </template>
                                        <template v-slot:[`item.hutang`]="{ item }">
                                            {{ $store.getters.convertToCurrency(item.hutang) }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'SR MALL',
                disabled: false,
                href: '/admin/srmall',
                },
                {
                text: 'Information',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Purchasing',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            loading_loader: false,
            offices: [],
            office: '',
            statuss: [
                {
                    value: 'A',
                    label: 'All'
                },
                {
                    value: 'N',
                    label: 'Belum Lunas'
                },
                {
                    value: 'Y',
                    label: 'Lunas'
                }
            ],
            status: '',
            startDate: '',
            endDate: '',
            dateNow: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuStart: false,
            menuEnd: false,
            vendors: [],
            vendor: '',
            display: 'none',
            hutangs: [],
            data_var: {
                entity_id : 'SRMALL',
                appl_id : 'APPROVAL'
            },
            options: {},
            option_temp_details:{},
            totalItems: 10,
            totalItem_details: 10,
            headers: [
                { text: 'Vendor', value: 'vendor_name', sortable: false },
                { text: 'Curr ID', value: 'curr_id', sortable: false },
                { text: 'Tagihan', value: 'tagihan', sortable: false },
                { text: 'Payment', value: 'payment', sortable: false },
                { text: 'Outstanding', value: 'hutang', sortable: false },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            header_details:[
                {
                    text: 'Date Inv',
                    align: 'start',
                    sortable: true,
                    value: 'dt_inv',
                    width: '10%'
                },
                { text: 'TR ID', value: 'tr_id' },
                { text: 'Inv ID', value: 'inv_id' },
                { text: 'Vendor', value: 'vendor_name' },
                { text: 'Pay term', value: 'pay_term_desc' },
                { text: 'Curr ID', value: 'curr_id' },
                { text: 'Amt Subtotal', value: 'amt_subtotal' },
                { text: 'Amt Disc', value: 'amt_disc' },
                { text: 'Amt PPN', value: 'amt_ppn' },
                { text: 'Amt Total', value: 'amt_total' },
                { text: 'Amt Paid', value: 'amt_paid' },
                { text: 'Amt Paid Disc', value: 'amt_paid_disc' },
                { text: 'Outstanding', value: 'hutang' },
                // { text: 'Actions',  value: 'actions', sortable: false },
            ],
            order_details: [],
            dialog: false,
            data_temp: {},
            search_text: '',
            loading2: false,
            pagination: 1,
            pagination_item: 1,
            total_hutang: 0,
            total_tagihan: 0,
            total_payment: 0,
            sortBy: 'desc',
            sortName: ''
        }
    },
    mounted(){
        this.getOffice()
        this.getVendor()
    },
    methods:{
        sortByFunc(sortBy){
            console.log(this.sortBy, sortBy);
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.submit(1, 10)
        },
        async submit(page = 1, itemsPerPage = 10){
            this.loading = true
            this.loading_loader = true
            
            await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/purchasing?search=${this.$store.state.text}&txtOffice=${this.office ? this.office : ''}&vendor=${this.vendor ? this.vendor : ''}&status=${this.status ? this.status : ''}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data);
                this.loading = false
                this.loading_loader = false
                this.display = 'block'
                this.total_hutang = res.data.total_hutang
                this.total_payment = res.data.total_payment
                this.total_tagihan = res.data.total_tagihan

                this.hutangs = res.data.data.data
                this.totalItems = res.data.data.total
                this.pagination = res.data.data.current_page
            })
        },
        showItem(item){
            this.data_temp = item
            this.dialog = true
            this.order_details = []
            this.getDetailOrder(1, 10, item)
        },
        async getDetailOrder(page = 1, itemsPerPage = 10, item){
            this.loading2 = true
            await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/purchasing/${item.vendor_id}?search=${this.search_text ? this.search_text : ''}&office_id=${item.office_id}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading2 = false
                this.order_details = res.data.data
                this.totalItem_details = res.data.total
                this.pagination_item = res.data.current_page
            })
        },
        close(){
            this.dialog = false
        },
        search(){
            this.hutangs = []
            this.pagination = 1
            this.submit(1, 10)
        },
        search_detail(){
            this.order_details = []
            this.pagination_item = 1
            this.getDetailOrder(1, 10, this.data_temp)
        },
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data
                this.office = res.data[0].office_id
            });
        },
        async getVendor(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr_mall/vendor`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.vendors = res.data
            });
        }
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.office != '') {
                    this.submit(page, itemsPerPage)
                }
            },
            deep: true,
        },
        option_temp_details: {
            handler () {
                const { page, itemsPerPage } = this.option_temp_details
                if (this.data_temp != '') {
                    this.getDetailOrder(page, itemsPerPage, this.data_temp)
                }
            },
            deep: true,
        }
    }
}
</script>